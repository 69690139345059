import * as React from "react";
import CovidIntent from "../covid-intent/covid-intent";
import NoDetectedIntent from "../covid-intent/no-detected-intent/no-detected-intent";
import LoadingState from "../../ui-library/loading-state/loading-state";
import CountrySituation from "../covid-intent/special-intents/country-situation";
import PageContainer from "../../ui-library/page-container/page-container";
import Navigation from "../../site/navigation/navigation";
import { TOP_NAV_ITEM } from "../../../constants/top-navigations";
import { SPECIAL_INTENTS } from "../../../constants/special-intents";
import CountryGovResTimeline from "../covid-intent/special-intents/country-gov-res-timeline";
import { Topic } from "../../../types/topics";
import { useQueryDetectCovidIntent } from "../../../hooks/live-queries/use-query-detect-intent/use-query-detect-intent";

interface DetectCovidIntentProps {
  term: string;
}

const DetectCovidIntent: React.FC<DetectCovidIntentProps> = ({ term }) => {
  const {
    loading,
    intentName,
    confidence,
    parameters,
  } = useQueryDetectCovidIntent(term);

  if (loading) {
    return (
      <>
        <Navigation topic={Topic.COVID19} />
        <PageContainer>
          <LoadingState />
        </PageContainer>
      </>
    );
  }

  if (!intentName || intentName.trim() === "" || !confidence) {
    return (
      <>
        <Navigation topic={Topic.COVID19} />
        <PageContainer>
          <NoDetectedIntent />
        </PageContainer>
      </>
    );
  }

  if (intentName === "What is the COVID-19 situation in Country?") {
    return (
      <>
        <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.Insights} />
        <PageContainer>
          <CountrySituation
            term={term}
            intentName={intentName}
            parameters={parameters}
            intentDetectionConfidence={confidence}
          />
        </PageContainer>
      </>
    );
  }

  if (intentName === "What's the government response timeline for Country?") {
    return (
      <>
        <Navigation topic={Topic.COVID19} activeItem={TOP_NAV_ITEM.Insights} />
        <PageContainer>
          <CountryGovResTimeline
            term={term}
            intentName={intentName}
            parameters={parameters}
            intentDetectionConfidence={confidence}
          />
        </PageContainer>
      </>
    );
  }

  const matchingIntentKey = Object.keys(SPECIAL_INTENTS).find((k) => {
    return SPECIAL_INTENTS[k].name === intentName;
  });

  return (
    <>
      <Navigation
        topic={Topic.COVID19}
        activeItem={
          matchingIntentKey ? SPECIAL_INTENTS[matchingIntentKey].nav : undefined
        }
      />

      <PageContainer>
        <CovidIntent
          term={term}
          intentName={intentName}
          intentDetectionConfidence={confidence}
        />
      </PageContainer>
    </>
  );
};

export default DetectCovidIntent;
