import * as React from "react";
import { PageProps } from "gatsby";
import * as queryString from "query-string";

import Layout from "../../components/layout";
import SEO from "../../components/seo/seo";
import DetectCovidIntent from "../../components/covid-19/detect-covid-intent/detect-covid-intent";
import { LogoBunny } from "../../components/site/header/header";
import { Topic } from "../../types/topics";
import socialImage from "../../images/ask-about-social-covid.jpg";

// interface Covid19AskPageProps {}
type Covid19AskPageProps = PageProps<null, null>;

const Covid19AskPage: React.FC<Covid19AskPageProps> = ({ location }) => {
  // @ts-ignore no term
  const term: string = location?.state?.term;
  const search = queryString.parse(location?.search);
  const termFromQuery = search?.ask as string | undefined;

  return (
    <Layout
      topic={Topic.COVID19}
      description="coronavirus"
      headerUrl="/covid-19/"
      logoBunny={LogoBunny.FaceMask}
      term={term}
    >
      <SEO
        title="Ask a COVID-19 question"
        description="Ask a question around 2019 novel coronavirus and we’ll show you an answer"
        imageUrl={socialImage}
      />

      <DetectCovidIntent term={term || termFromQuery || ""} />
    </Layout>
  );
};

export default Covid19AskPage;
