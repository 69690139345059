import * as React from "react";
import PageHeading from "../../../ui-library/page-heading/page-heading";
import { addQuestionMarkToTerm } from "../../../../utils/format-term";
import Container from "../../../ui-library/container/container";
import CollectQAndA from "../../../common-page-sections/collect-q-and-a/collect-q-and-a";
import DailyCaseByCountry from "../intent-specific-answers/daily-case-by-country/daily-case-by-country";
import SuggestedIntent from "../../../common-page-sections/suggested-intent/suggested-intent";
import { COUNTRY_MAPPING } from "../../../../constants/countries";
import { Topic } from "../../../../types/topics";
import ECVBanner from "../../third-party-banner/ecv-banner";

interface CountrySituationProps {
  term: string;
  intentName: string;
  parameters?: {
    "geo-country": { stringValue: string };
  };
  intentDetectionConfidence: number;
}

const CountrySituation: React.FC<CountrySituationProps> = ({
  term,
  intentName,
  parameters,
  intentDetectionConfidence,
}) => {
  const countryName = parameters
    ? parameters["geo-country"].stringValue
    : "N/A";

  const countryMatch = COUNTRY_MAPPING[countryName];
  const country = countryMatch ? countryMatch : countryName;

  const alias = country
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/['|(|)]/g, "");

  return (
    <div>
      <PageHeading
        subHeading={
          intentDetectionConfidence <= 1
            ? `You asked: ${addQuestionMarkToTerm(term)}...`
            : undefined
        }
        detail={`${Math.round(intentDetectionConfidence * 100)}% confidence`}
      >
        {intentName.replace("Country", countryName)}
      </PageHeading>

      <Container fullWidthOnMobile={true}>
        <DailyCaseByCountry
          country={country}
          countryName={countryName}
          intentName={intentName.replace("Country", countryName)}
        />
      </Container>

      <Container>
        <SuggestedIntent
          suggestedIntent={{
            alias: `what-is-the-government-response-timeline-for-${alias}`,
            displayName: `What's the government response timeline for ${countryName}?`,
          }}
          topic={Topic.COVID19}
        />

        <ECVBanner />

        <CollectQAndA />
      </Container>
    </div>
  );
};

export default CountrySituation;
