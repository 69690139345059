export const COUNTRIES = [
  "United Kingdom",
  "Australia",
  "United States",
  "South Korea",
  "Italy",
  "China",
  "Singapore",
  "France",
  "Spain",
  "New Zealand",
  "Iran",
  "Germany",
  "Sweden",
  "Switzerland",
  "Russia",
  "India",
  "Japan",
  "Belgium",
];

export const COUNTRY_MAPPING: { [key: string]: string } = {
  "Democratic Republic of the Congo": "Democratic Republic of Congo",
  "Republic of the Congo": "Congo",
  "Côte d'Ivoire": "Cote d'Ivoire",
  "Caribbean Netherlands": "Bonaire Sint Eustatius and Saba",
  Czechia: "Czech Republic",
  "Falkland Islands (Islas Malvinas)": "Falkland Islands",
  "Faroe Islands": "Faeroe Islands",
  "São Tomé and Príncipe": "Sao Tome and Principe",
  "Sint Maarten": "Sint Maarten (Dutch part)",
  "Timor-Leste": "Timor",
  "U.S. Virgin Islands": "United States Virgin Islands",
};
