import * as React from "react";
import PageHeading from "../../../ui-library/page-heading/page-heading";
import { addQuestionMarkToTerm } from "../../../../utils/format-term";
import Container from "../../../ui-library/container/container";
import CollectQAndA from "../../../common-page-sections/collect-q-and-a/collect-q-and-a";
import SuggestedIntent from "../../../common-page-sections/suggested-intent/suggested-intent";
import { COUNTRY_MAPPING } from "../../../../constants/countries";
import GovernmentResponseTimeline from "../intent-specific-answers/government-response-timeline/government-response-timeline";
import { useQueryGovResTimeline } from "../../../../hooks/live-queries/use-query-gov-res-timeline/use-query-gov-res-timeline";
import { Topic } from "../../../../types/topics";
import ECVBanner from "../../third-party-banner/ecv-banner";

interface CountryGovResTimelineProps {
  term: string;
  intentName: string;
  parameters?: {
    "geo-country": { stringValue: string };
  };
  intentDetectionConfidence: number;
}

const CountryGovResTimeline: React.FC<CountryGovResTimelineProps> = ({
  term,
  intentName,
  parameters,
  intentDetectionConfidence,
}) => {
  const countryName = parameters
    ? parameters["geo-country"].stringValue
    : "N/A";

  const countryMatch = COUNTRY_MAPPING[countryName];
  const country = countryMatch ? countryMatch : countryName;

  const { responseTimeline } = useQueryGovResTimeline(country);

  const alias = country
    .toLowerCase()
    .replace(/ /g, "-")
    .replace(/['|(|)]/g, "");

  return (
    <div>
      <PageHeading
        subHeading={
          intentDetectionConfidence <= 1
            ? `You asked: ${addQuestionMarkToTerm(term)}...`
            : undefined
        }
        detail={`${Math.round(intentDetectionConfidence * 100)}% confidence`}
      >
        {intentName.replace("Country", countryName)}
      </PageHeading>

      <Container fullWidthOnMobile={true}>
        <GovernmentResponseTimeline
          country={country}
          countryName={countryName}
          intentName={intentName.replace("Country", countryName)}
          responseTimeline={responseTimeline}
        />
      </Container>

      <Container>
        <SuggestedIntent
          suggestedIntent={{
            alias: `what-is-the-covid-19-situation-in-${alias}`,
            displayName: `What is the COVID-19 situation in ${countryName}?`,
          }}
          topic={Topic.COVID19}
        />

        <ECVBanner />

        <CollectQAndA />
      </Container>
    </div>
  );
};

export default CountryGovResTimeline;
